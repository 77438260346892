<template>
  <v-form
    v-model="isFormValid"
  >
    <h3>{{ $t('subscription.selfDisclosure.title') }}</h3>
    <br/>
    <p class="font-weight-bold primary--text">{{ $t('subscription.selfDisclosure.beneficialOwner.title') }}</p>
    <v-checkbox
      v-model="checkbox.beneficialOwner"
      :rules="rules.required"
      :label="$t('subscription.selfDisclosure.beneficialOwner.text')"
      color="primary"
    ></v-checkbox>
    <br/>
    <p
      v-if="allowLevels(['level2', 'level3'])"
      class="font-weight-bold primary--text"
    >
      {{ $t(`subscription.selfDisclosure.information.${investmentLevel}`) }}
    </p>
    <v-checkbox
      v-if="allowLevels(['level2', 'level3'])"
      v-model="checkbox.statementWealth"
      :rules="rules.required"
      :label="$t('subscription.selfDisclosure.statementWealth')"
      color="primary"
    ></v-checkbox>
    <v-checkbox
      v-if="allowLevels(['level2'])"
      v-model="checkbox.statementIncome"
      :rules="rules.required"
      :label="$t('subscription.selfDisclosure.statementIncome')"
      color="primary"
    ></v-checkbox>
  </v-form>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { required } from '@/utils/validators';

export default {
  name: 'SelfDisclosure',
  props: {
    investmentLevel: {
      type: String,
      default: 'level1',
    },
  },
  data() {
    return {
      checkbox: {
        beneficialOwner: false,
        statementWealth: false,
        statementIncome: false,
      },
      rules: {
        required,
      },
    };
  },
  computed: {
    ...mapFields('subscription', [
      'isFormValid',
    ]),
  },
  methods: {
    allowLevels(levels) {
      return levels.includes(this.investmentLevel);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
